@font-face {
  font-family:MaerskIcons-20px;src:url(../webfonts/MaerskIcons-20px.19be0205.eot);src:url(../webfonts/MaerskIcons-20px.19be0205.eot?#iefix) format("embedded-opentype"),url(../webfonts/MaerskIcons-20px.db00e95d.woff2) format("woff2"),url(../webfonts/MaerskIcons-20px.d737669d.woff) format("woff");
}

.mi-20px {
  font-family: MaerskIcons-20px!important;
  font-size: 20px;
}

@font-face {
  font-family:MaerskIcons-24px;src:url(../webfonts/MaerskIcons-24px.71697fb4.eot);src:url(../webfonts/MaerskIcons-24px.71697fb4.eot?#iefix) format("embedded-opentype"),url(../webfonts/MaerskIcons-24px.10a21282.woff2) format("woff2"),url(../webfonts/MaerskIcons-24px.31e6775e.woff) format("woff");
}

.mi-20px,.mi-24px {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
  text-rendering: auto;
  text-transform: none;
}


.mi-24px {
  font-family: MaerskIcons-24px!important;
  font-size: 24px;
}

.mi-anchor:before {
  content: "";
}

.mi-arrow-anti-clockwise-clock:before {
  content: "";
}

.mi-arrow-clockwise-clock:before {
  content: "";
}

.mi-arrow-clockwise-times:before {
  content: "";
}

.mi-arrow-clockwise:before {
  content: "";
}

.mi-arrow-down-left:before {
  content: "";
}

.mi-arrow-down-right:before {
  content: "";
}

.mi-arrow-down:before {
  content: "";
}

.mi-arrow-from-bottom:before {
  content: "";
}

.mi-arrow-from-left:before {
  content: "";
}

.mi-arrow-from-right:before {
  content: "";
}

.mi-arrow-from-top:before {
  content: "";
}

.mi-arrow-left:before {
  content: "";
}

.mi-arrow-right:before {
  content: "";
}

.mi-arrow-to-bottom:before {
  content: "";
}

.mi-arrow-to-left:before {
  content: "";
}

.mi-arrow-to-right:before {
  content: "";
}

.mi-arrow-to-top:before {
  content: "";
}

.mi-arrow-up-left:before {
  content: "";
}

.mi-arrow-up-right:before {
  content: "";
}

.mi-arrow-up:before {
  content: "";
}

.mi-arrows-down-up:before {
  content: "";
}

.mi-arrows-left-right:before {
  content: "";
}

.mi-bars-horizontal-funnel-down:before {
  content: "";
}

.mi-bars-horizontal-funnel-left:before {
  content: "";
}

.mi-bars-horizontal:before {
  content: "";
}

.mi-bell:before {
  content: "";
}

.mi-book-open:before {
  content: "";
}

.mi-box:before {
  content: "";
}

.mi-browser:before {
  content: "";
}

.mi-calendar:before {
  content: "";
}

.mi-caret-down:before {
  content: "";
}

.mi-caret-left:before {
  content: "";
}

.mi-caret-right:before {
  content: "";
}

.mi-caret-up:before {
  content: "";
}

.mi-check-circle:before {
  content: "";
}

.mi-check:before {
  content: "";
}

.mi-chevron-down:before {
  content: "";
}

.mi-chevron-left:before {
  content: "";
}

.mi-chevron-right:before {
  content: "� ";
}

.mi-chevron-up:before {
  content: "";
}

.mi-chevrons-left-right:before {
  content: "";
}

.mi-chevrons-up-down:before {
  content: "";
}

.mi-clipboard-check-text:before {
  content: "";
}

.mi-clipboard-check:before {
  content: "";
}

.mi-clock-alarm:before {
  content: "";
}

.mi-clock-stopwatch:before {
  content: "";
}

.mi-clock:before {
  content: "";
}

.mi-cog:before {
  content: "";
}

.mi-combined-arrow-down:before {
  content: "";
}

.mi-combined-arrow-left:before {
  content: "";
}

.mi-combined-arrow-right:before {
  content: "";
}

.mi-combined-arrow-up:before {
  content: "";
}

.mi-comments:before {
  content: "";
}

.mi-computer:before {
  content: "";
}

.mi-container-down:before {
  content: "";
}

.mi-container-flat-rack:before {
  content: "";
}

.mi-container-high-cube:before {
  content: "";
}

.mi-container-hook:before {
  content: "";
}

.mi-container-open-top:before {
  content: "";
}

.mi-container-reefer:before {
  content: "";
}

.mi-container-stacked:before {
  content: "";
}

.mi-container-tank:before {
  content: "";
}

.mi-container-tracking:before {
  content: "";
}

.mi-container-up:before {
  content: "";
}

.mi-container:before {
  content: "";
}

.mi-dollar-circle:before {
  content: "";
}

.mi-door-arrow-right:before {
  content: "";
}

.mi-double-chevron-down:before {
  content: "";
}

.mi-double-chevron-left:before {
  content: "";
}

.mi-double-chevron-right:before {
  content: "";
}

.mi-double-chevron-up:before {
  content: "";
}

.mi-droplet:before {
  content: "";
}

.mi-ellipsis-vertical:before {
  content: "";
}

.mi-envelope:before {
  content: "";
}

.mi-exclamation-circle:before {
  content: "";
}

.mi-exclamation-octagon:before {
  content: "";
}

.mi-exclamation-triangle:before {
  content: "";
}

.mi-expand:before {
  content: "";
}

.mi-eye-slash:before {
  content: "";
}

.mi-eye:before {
  content: "";
}

.mi-file-copy:before {
  content: "";
}

.mi-file:before {
  content: "";
}

.mi-flame:before {
  content: "";
}

.mi-floppy-disk:before {
  content: "";
}

.mi-folder-arrow-down:before {
  content: "";
}

.mi-folder-arrow-up:before {
  content: "";
}

.mi-globe:before {
  content: "";
}

.mi-heart:before {
  content: "";
}

.mi-hook-arrow-left:before {
  content: "";
}

.mi-hook-arrow-right:before {
  content: "";
}

.mi-info-circle:before {
  content: "";
}

.mi-link:before {
  content: "";
}

.mi-lock:before {
  content: "";
}

.mi-magnifying-glass:before {
  content: "";
}

.mi-man:before {
  content: "";
}

.mi-megaphone:before {
  content: "";
}

.mi-microphone:before {
  content: "";
}

.mi-minus:before {
  content: "";
}

.mi-mouse:before {
  content: "";
}

.mi-pause:before {
  content: "";
}

.mi-pencil:before {
  content: "";
}

.mi-people:before {
  content: "";
}

.mi-person:before {
  content: "� ";
}

.mi-phone-call:before {
  content: "";
}

.mi-phone:before {
  content: "";
}

.mi-pin:before {
  content: "";
}

.mi-play-circle:before {
  content: "";
}

.mi-plus:before {
  content: "";
}

.mi-printer:before {
  content: "";
}

.mi-question-circle:before {
  content: "";
}

.mi-quote:before {
  content: "";
}

.mi-receipt:before {
  content: "";
}

.mi-rotate:before {
  content: "";
}

.mi-share:before {
  content: "";
}

.mi-shrink:before {
  content: "";
}

.mi-sliders-horizontal:before {
  content: "";
}

.mi-snowflake:before {
  content: "";
}

.mi-square-arrow-up-right:before {
  content: "";
}

.mi-star:before {
  content: "";
}

.mi-thumbs-up:before {
  content: "";
}

.mi-times-circle:before {
  content: "";
}

.mi-times:before {
  content: "";
}

.mi-train-front:before {
  content: "";
}

.mi-trash:before {
  content: "";
}

.mi-tray-arrow-down:before {
  content: "";
}

.mi-tray-arrow-up:before {
  content: "";
}

.mi-truck-front:before {
  content: "";
}

.mi-truck-side:before {
  content: "";
}

.mi-tugboat-side:before {
  content: "";
}

.mi-user-circle:before {
  content: "";
}

.mi-user-plus-circle:before {
  content: "";
}

.mi-vessel-front:before {
  content: "";
}

.mi-wifi:before {
  content: "";
}

.mi-wrench:before {
  content: "";
}
