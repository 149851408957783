@font-face {
  font-family:Maersk Text;font-style:normal;font-weight:400;font-display:swap;src:url('../fonts/maersk/MaerskText-Regular.eot') format("eot"),url('../fonts/maersk/MaerskText-Regular.woff2') format("woff2"),url('../fonts/maersk/MaerskText-Regular.woff') format("woff");
}

@font-face {
  font-family:Maersk Text;font-style:normal;font-weight:700;font-display:swap;src:url('../fonts/maersk/MaerskText-Bold.eot') format("eot"),url('../fonts/maersk/MaerskText-Bold.woff2') format("woff2"),url('../fonts/maersk/MaerskText-Bold.woff') format("woff");
}

body {
  margin: 0px;
  font-family: Maersk Text,sans-serif;
}

/* PIE */

.ind-footSmall {
	padding: 2px 0px;
}

.ind-footFondo {
	width: 100%;
	height: 37px;
}

.ind-footNav {
	padding: 0px;
    -webkit-box-shadow: 0px -2px 6px -1px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px -2px 6px -1px rgba(0,0,0,0.4);
    box-shadow: 0px -2px 6px -1px rgba(0,0,0,0.4);
    background-color: #004165;
}

.ind-footText {
    padding-top: .3rem;
    padding-bottom: .3rem;
    font-size: 12px;
}

.ind-footImg {
	margin-right: 0.5rem;
    display: inline-block;
	/*
	background-image: url('../img/logo-02.png');
    width: 220px;
    height: 10px;*/
}
 
.ind-footImgv2 {
	margin-right: 0.5rem;
    display: inline-block;
	/*
	background-image: url('../img/logo-03.png');
    width: 202px;
    height: 15px;*/
}

.ind-footConex {
    position: absolute; 
    right: 0.3rem; 
    top: .6rem;
}

/* -------------- */

/* ohCore */

.ohCore-body-content {
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  min-height: fit-content; /*new*/
}

.ohCore-body-center {
  text-align: center;
}

.ohCore-body-font {
  color: #fff !important;
  font-weight: 600;
}

.ohCore-body-img {
  background-image: url('../img/loading.svg');
  display: inline-block;
  font-weight: 300;
  width: 100px;
  height: 100px;
  background-size: contain;
}

/* -------------- */

/* custom */

@media screen and (max-width: 576px) {

	.vistaA {
	    display: inline-block!important;
	}
	.vistaAInline {
	    display: inline-block!important;
	}
	.vistaACell {
	    display: table-cell!important;
	}
	.vistaB {
	    display: none!important;
	}
	.vistaC {
	    display: none!important;
	}
	.vistaBC {
	    display: none!important;
	}
	.vistaAB {
	    display: inline-block!important;
	}
	
}

@media screen and (min-width: 576px) and (max-width: 768px) { 

	.vistaA {
	    display: none!important;
	}
	.vistaAInline {
	    display: none!important;
	}
	.vistaACell {
	    display: none!important;
	}
	.vistaB {
	    display: inline-block!important;
	}
	.vistaC {
	    display: none!important;
	}
	.vistaBC {
	    display: inline-block!important;
	}
	.vistaAB {
	    display: inline-block!important;
	}
	
}

@media screen and (min-width: 768px) {

	.vistaA {
	    display: none!important;
	}
	.vistaAInline {
	    display: none!important;
	}
	.vistaACell {
	    display: none!important;
	}
	.vistaB {
	    display: none!important;
	}
	.vistaC {
	    display: inline-block!important;
	}
	.vistaBC {
	    display: inline-block!important;
	}
	.vistaAB {
	    display: none!important;
	}

}

.ind-body-maxContent:before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 4s ease-in-out;
}

.ind-body-maxContent {
  position: fixed;
  top: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #003f66;
  min-height: 100%;
  overflow: auto; /*new*/
}

.img_logo_01_svg {
	background-image: url("../img/logo-01.svg");
    display: inline-block;
    width: 360px;
    height: 118px;
}

/* For texts */

.text-size-15 {
	font-size: 1.5rem;
}

.text-size-15i {
	font-size: 1.5rem !important;
}

.text-size-10 {
	font-size: 1.0rem;
}

.text-size-8 {
	font-size: 0.8rem;
}

.text-GrayBlue {
	color : #acc6d1;
}

.text-bold {
	font-weight: bold;
}

/* Material css */
.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
    display: flex;
}