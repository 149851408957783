body {
	/*font-family: 'CaviarDreams';*/
	background-color: #edf2f5;
}

a {
	color: #ff6303;
	text-decoration: none;
	font-weight: 400;
}

a:focus, a:hover {
    color: #fd7b2b;
}

*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
	background-color: #f7f7f7;
}

*::-webkit-scrollbar {
	width: 0.3rem;
	height: 0.3rem;
	background-color: #f7f7f7;
}

@media screen and (max-width: 576px) {
	*::-webkit-scrollbar {
		width: 0.1rem;
		height: 0.1rem;
	}
}

@media screen and (min-width: 576px) and (max-width: 768px) { 
	*::-webkit-scrollbar {
		width: 0.2rem;
		height: 0.2rem;
	}
}

*::-webkit-scrollbar-thumb {
	background-color: #ff641a;
}

.alert-form {
	padding: 0.25rem 0.75rem;
    margin-top: 0.25rem;
    margin-bottom: 0rem;
}

.alert-form ul {
	padding-left: 1rem;
	margin-bottom: 0;
}

.badge-outline-primary {
	color: #ff6303;
    /*background-color: #DFF7EF;*/
    border-color: #ff6303;
}

.badge-outline-success {
	color: #28a745;
    /*background-color: #DFF7EF;*/
    border-color: #28a745;
}

.badge-outline-warning {
	color: #856e00;
    /*background-color: #f561063b;*/
    border-color: #856e00;
}

.badge-outline-danger {
	color: #dc3545;
	/*background-color: #FEEEE4;*/
	border-color: #dc3545;
}

.badge-outline-info {
	color: #17a2b8;
	/*background-color: #FEEEE4;*/
	border-color: #17a2b8;
}

.badge-outline-secondary {
	color: #b76ad6;
	/*background-color: #FEEEE4;*/
	border-color: #b76ad6;
}

.mb-8 {
	margin-bottom: 8rem;
}

.btn-link-buttom {
    position: relative;
}

.btn-link-buttom .badge {
    position: absolute;
    top: -0.7rem;
    right: -0.7rem;
    font-size: 0.8rem;
}

.form-list-filter {
	color: #004165;
    border-bottom: 1px solid #004165;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 0.87rem;
    height: calc(2rem + 2px);
    border-radius: .25rem;
    font-weight: bold;
    line-height: 1.5;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
}

.form-list-filter::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #1c79ad;
  opacity: 1; /* Firefox */
}

.form-list-filter:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #1c79ad;
}

.form-list-filter::-ms-input-placeholder { /* Microsoft Edge */
  color: #1c79ad;
}

.table-wrapper th {
    position: sticky;
    top: 0;
	background-color: white;
}

.table-wrapper thead tr th {
	z-index: 10!important;
}