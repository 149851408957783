.form-control {
    border: 1px solid #dacece;
}

.input-group-text {
	cursor: pointer;
}

.btn-outline-info:not([disabled]):not(.disabled).active, .btn-outline-info:not([disabled]):not(.disabled):active, .show>.btn-outline-info.dropdown-toggle {
    color: white;
}

.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .45rem 0rem;
	list-style: none;
	background-color: inherit;
}

.breadcrumb-item.active {
    color: #FF6303;
}

/* Tables */

.table {
    border: 1px solid #e5e5e5;
    font-size: 13px;
}

.table thead {
    color: #004165;
}

.table td, .table th {
    padding: .75rem;
    vertical-align: middle;
    border-top: 1px solid #eceeef;
}

.table tbody {
	color: #949a9e;
    font-weight: 300;
    letter-spacing: .4px;
}
.table-hover tbody tr:hover {
	background-color: #dbf1f5;
}

.tabs-contenido .tab-content {
    background-color: white;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.020);
}

/* ---- */

.page-link:hover {
    color: #821906;
}

.page-link {
    color: #FF6303;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #ff2700;
    border-color: #821906;
}

.btn-primary {
	color: #fff;
	background-color: #FF6303;
	border-color: #FF6303;
}

.text-primary {
    color: #ff6303!important;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
	background-color: #ec8443;
	border-color: #af4300;
}

.btn-primary:hover {
	color: #fff;
	background-color: #EF6713;
	border-color: #af4300;
}

.btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 129, 51, 0.5);
}

.btn-secondary {
	color: #fff;
	background-color: #687c8d;
	border-color: #4d5a66;
}

.btn-secondary:hover {
	color: #fff;
	background-color: #4e6477;
	border-color: #3a4a58;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
	border-color: #af4300;
    box-shadow: 0 0 0 0.2rem rgba(255, 129, 51, 0.5);
}

.form-enable .ng-valid[required]:not([formTinymce]), 
.form-enable .ng-valid.required:not([formTinymce]), 
.form-enable .ng-valid[formcontrolname]:not([formTinymce]):not([ohdate]):not([daterange]), 
.form-enable .ng-valid.formcontrolname:not([formTinymce]) {
  border-left: 5px solid #42A948; /* green */
}

.form-enable .ng-valid[required] div[role='application'] {
  border-radius: .25rem;
  border-left: 5px solid #42A948!important; /* green */
}

.form-enable .ng-invalid:not(form):not([formTinymce]):not([ohdate]):not([daterange]) {
  border-left: 5px solid #a94442; /* red */
}

.form-enable .ng-invalid:not(form) div[role='application'] {
	border-radius: .25rem;
	border-left: 5px solid #a94442!important; /* red */
}

.modal {
    z-index: 1050;
}

/* Badges */
.badge-pill {
    padding: 0.3rem 0.4rem;
    font-weight: 600;
    border: 1px solid;
}

/* Font Size */
table, p {
	font-size: 0.87rem;
}

label {
	font-size: 0.87rem;
}

.btn {
	font-size: 0.87rem;
}

.form-control, .custom-select {
	font-size: 0.87rem;
	height: calc(2rem + 3px);
}

ngb-alert {
    font-size: 0.9rem;
	padding-bottom: 0px !important;
}

table .switch {
    transform: scale(0.8);
}

.col-form-label {
	font-size: 0.87rem !important;
}

.input-group-text {
	font-size: 0.86rem !important; 
}

ngb-datepicker {
	font-size: 0.87rem !important;
}

.page-item.active .page-link {
    background-color: #FF6303;
    border-color: #FF6303;
}

span.oh_link_text {
	font-size: 1rem !important;
}

.btn-info {
    color: #fff;
    background-color: #3cb5cd;
    border-color: #3cb5cd;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-info {
    color: #3cb5cd;
    background-color: transparent;
    background-image: none;
    border-color: #3cb5cd;
}

.btn-outline-info:hover {
    color: #ffffff;
    background-color: #3cb5cd;
    background-image: none;
    border-color: #3cb5cd;
}

.modal-content form {
	display: contents;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #f0f1f3;
    opacity: 1;
    background: linear-gradient(0deg, rgba(228,230,237,1) 0%, rgba(240,241,243,1) 35%, rgba(240,241,243,1) 100%);
}

.ngb-dp-day.disabled span {
    color: #bbbbbb;
}

select.input-lg {
    height: 46px;
    line-height: 46px;
}

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}